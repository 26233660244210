
$primary-yellow: #edd43e;
$primary-blue: #14c5cc;
$title-font: sofia-pro-soft, sans-serif;
$title-weight: 700;
$text-font: sofia-pro, sans-serif;
$text-weight: 400;

* {
  margin: 0;
  
}

h2, h3, h4, h5, h6, p, span, button {
  font-family: $text-font;
}



.heading {
  text-align: center;

  h2{
    font-family: $title-font;
    font-weight: $title-weight;
    font-size: 1.8em;
  }
  color: white;
  
  padding-top: 30px;

}
.mission {
  padding: 20px;
  max-width: 1024px;
  margin:auto;
}
.logo-container {
  position:relative;
  display: flex;
  justify-content: center;
  align-items:center;
  .flex {
    padding: 0px 10px;
  }
}

.button {
    background-color: white;
    border: solid;
    color: black;
    padding: 8px 20px;
    font-family: $text-font;
    text-align: center;
    display: inline-block;
    font-size: 1em;
    border-radius: 16px;
}

.top-shape-heading-rectangle {
  background: $primary-blue;
  width: 100vw;
  height: 100px;
}

.top-shape-heading-triangle {
  position:absolute;
  top:0;
  right:0;
  width: 0;
  height: 0;
  border-top: 150px solid $primary-blue;
  border-left: 100vw solid transparent;
  z-index: -100;
}

.coming-soon {
  padding-top: 20px;
  font-size: 1.5em;
  text-align:center;
  font-style: italic;
}



.podcast-logo-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: 150px;
  width: 200px;
}

.sample {
  margin: auto;
  display: table;
  font-size: 1.2em;
  font-weight: 100;
  padding: 10px 0px;
  audio {
    margin: auto;
  }
}

.hosts {
  text-align: center;
  color:#aaa;
  font-size: 1.2em;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
